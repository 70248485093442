import { defineAsyncComponent } from 'vue';

export default {
  Comments: defineAsyncComponent(() => import('./Comments.vue')),
  NotificationPanel: defineAsyncComponent(() => import('./NotificationPanel.vue')),
  LoginModal: defineAsyncComponent(() => import('./modals/LoginModal.vue')),
  RankingTable: defineAsyncComponent(() => import('./blocks/RankingTable.vue')),
  PromoCodesTable: defineAsyncComponent(() => import('./blocks/PromoCodesTable.vue')),
  BestTypers: defineAsyncComponent(() => import('./blocks/BestTypers.vue')),
  BannerYesterdayMaxOdd: defineAsyncComponent(() => import('./blocks/BannerYesterdayMaxOdd.vue')),
  BannerBestAccuracy: defineAsyncComponent(() => import('./blocks/BannerBestAccuracy.vue')),
  BannerTd: defineAsyncComponent(() => import('./blocks/BannerTD.vue')),
  BannerCompetitionRecord: defineAsyncComponent(() => import('./blocks/BannerCompetitionRecord.vue')),
  BannerKoszulka: defineAsyncComponent(() => import('./blocks/BannerKoszulka.vue')),
  BannerKoszulkaWysuw: defineAsyncComponent(() => import('./blocks/BannerKoszulkaWysuw.vue')),
  EditorBet: defineAsyncComponent(() => import('./blocks/EditorBet.vue')),
  BetsCalculator: defineAsyncComponent(() => import('./blocks/BetsCalculator.vue')),
  LastBets: defineAsyncComponent(() => import('./blocks/LastBets.vue')),
  BonusContactForm: defineAsyncComponent(() => import('./blocks/BonusContactForm.vue')),
  BookmakerRating: defineAsyncComponent(() => import('./blocks/BookmakerRating.vue')),
  ScrollToCommentsButton: defineAsyncComponent(() => import('./blocks/ScrollToCommentsButton.vue')),
  WorthKnowing: defineAsyncComponent(() => import('./blocks/WorthKnowing.vue')),
  BettingOdds: defineAsyncComponent(() => import('./blocks/BettingOdds.vue')),
  FightCard: defineAsyncComponent(() => import('./blocks/FightCard.vue')),
  AdFoot: defineAsyncComponent(() => import('./blocks/AdFoot.vue')),
  Commentary: defineAsyncComponent(() => import('./blocks/Commentary.vue')),
  Competitors: defineAsyncComponent(() => import('./commentary_tabs/Competitors.vue')),
  FighterHistory: defineAsyncComponent(() => import('./blocks/FighterHistory.vue')),
  LiveChat: defineAsyncComponent(() => import('./LiveChat.vue')),
  KreczTabela: defineAsyncComponent(() => import('./blocks/KreczTabela.vue')),
  InvisibleBox: defineAsyncComponent(() => import('./blocks/InvisibleBox.vue')),
  DailyBetsMainPage: defineAsyncComponent(() => import('./DailyBetsMainPage.vue')),
  MartialArtsInfo: defineAsyncComponent(() => import('./blocks/MartialArtsInfo.vue')),
  RateArticle: defineAsyncComponent(() => import('./RateArticle.vue')),
  PromoCodeBox: defineAsyncComponent(() => import('./blocks/PromoCodeBox.vue')),
  UpcomingGalas: defineAsyncComponent(() => import('./blocks/UpcomingGalas.vue')),
  BookmakerDictionary: defineAsyncComponent(() => import('./blocks/BookmakerDictionary.vue')),
  WonCoupons: defineAsyncComponent(() => import('./coupons/WonCoupons.vue')),
  MainPageNotifications: defineAsyncComponent(() => import('./notifications/MainPageNotifications.vue')),
  SelectedFunctionalities: defineAsyncComponent(() => import('./blocks/SelectedFunctionalities.vue')),
  PromoCodeDetails: defineAsyncComponent(() => import('./promo_codes/PromoCodeDetails.vue')),
  FootballStatsTop: defineAsyncComponent(() => import('../sections/FootballStatsTop.vue')),
  OpeningHours: defineAsyncComponent(() => import('../components/blocks/OpeningHours.vue')),
  PostSummaryMobile: defineAsyncComponent(() => import('./blocks/PostSummaryMobile.vue')),
  PostSummaryDesktop: defineAsyncComponent(() => import('./blocks/PostSummaryDesktop.vue')),
	BookmakerBonusesTables: defineAsyncComponent(() => import('./BookmakerBonusesTables.vue')),
	FootballStatsScorers: defineAsyncComponent(() => import('./blocks/FootballStatsScorers.vue')),
	FootballStatsNavigation: defineAsyncComponent(() => import('./blocks/FootballStatsNavigation.vue')),
	FootballStatsMain: defineAsyncComponent(() => import('./football_stats/views/StatsMain.vue')),
	StatsPage: defineAsyncComponent(() => import('./football_stats/views/StatsPage.vue')),
	FootballStatsMobileTopNav: defineAsyncComponent(() => import('./football_stats/blocks/MobileTopNav.vue')),
	FootballLeagueHistory: defineAsyncComponent(() => import('./football_stats/blocks/LeagueHistory.vue')),
	FootballTeam: defineAsyncComponent(() => import('./football_stats/views/FootballTeam.vue')),
  GalaSchedule: defineAsyncComponent(() => import('../sections/FightGalas.vue')),
	FootballStatsLeftSidebarSearch: defineAsyncComponent(() => import('../components/football_stats/blocks/LeftSidebarSearch.vue')),
	H2HOverviewTable: defineAsyncComponent(() => import('./football_stats/tables/H2hOverviewTable.vue')),
	FootballBets: defineAsyncComponent(() => import('./blocks/FootballBets.vue')),
	FootballLadder: defineAsyncComponent(() => import('./football_tables/Ladder.vue')),
	MainPageMatches: defineAsyncComponent(() => import('./football_stats/views/MainPageMatches.vue')),
	MainPageStats: defineAsyncComponent(() => import('./football_stats/views/MainPageStats.vue')),
	CountryMatches: defineAsyncComponent(() => import('./football_stats/views/CountryMatches.vue')),
	SuperBets: defineAsyncComponent(() => import('./football_stats/tables/SuperBets.vue')),
	SuggestedUsersSidebar: defineAsyncComponent(() => import('./disciplines/SuggestedUsersSidebar.vue')),
	PaymentProviders: defineAsyncComponent(() => import('./blocks/PaymentProviders.vue')),
	LimitedTimeBonus: defineAsyncComponent(() => import('./daily_bets_items/LimitedTimeBonuses.vue')),
  InfoBox: defineAsyncComponent(() => import('./football_stats/blocks/InfoBox.vue')),
  ErrorReport: defineAsyncComponent(() => import('./ErrorReport.vue')),
}